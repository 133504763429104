import styled, { keyframes } from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { ThemeType } from "@sablier/v2-themes";

const SpinnerCircleAnimation = keyframes`
    0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(360deg);

  }
`;

interface SpinnerCircleWrapperProps {
  color?: keyof ThemeType["colors"];
  size?: number | string;
  thickness: number | string;
}

const SpinnerCircleWrapper = styled.div<SpinnerCircleWrapperProps>`
  ${(props) => props.theme.styles.row}
  --spinner-color: currentColor;
  ${(props) =>
    props.color &&
    `--spinner-color: ${props.theme.colors[props.color] ?? props.color}`};
  & {
    position: relative;
    justify-content: center;
    width: ${(props) => props.size || props.theme.sizes.icon};
    height: ${(props) => props.size || props.theme.sizes.icon};
    font-size: ${(props) => props.thickness || "1.5"}px;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: ${SpinnerCircleAnimation} 1.1s infinite linear;

    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &:after {
      z-index: 2;
      border: 2.4px solid transparent;
      border-left: 2.4px solid var(--spinner-color);
      background-color: transparent;
    }

    &:before {
      z-index: 1;
      border: 2.4px solid var(--spinner-color);
      opacity: 0.2;
    }
  }
`;

interface Props {
  className?: string;
  color?: keyof ThemeType["colors"];
  size?: number;
  thickness?: number;
  isVisible?: boolean;
}

function SpinnerCircle({
  className,
  color,
  isVisible = true,
  size,
  thickness = 1.5,
}: Props) {
  return (
    <SpinnerCircleWrapper
      data-visible={isVisible}
      className={className}
      color={color}
      size={size && _.toSuffix(size, "px")}
      thickness={thickness}
    />
  );
}

function Spinner({
  className,
  color,
  shape = "circle",
  ...otherProps
}: Props & { shape?: string }) {
  switch (shape) {
    case "circle":
      return (
        <SpinnerCircle className={className} color={color} {...otherProps} />
      );
    default:
      return <></>;
  }
}

export default Spinner;
