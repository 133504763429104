import styled, { keyframes } from "styled-components";
import { Decor } from "@sablier/v2-components/atoms";

const lineKeyframes = keyframes`
  0%, 100%{
    transform: translateY(-50px);
  }

  25%{
    transform: translateY(20px);
  }

  50%{
    transform: translateY(50px);
  }
`;

const rectangleKeyframes = keyframes`
  0%{
    stroke-dashoffset: 600;
  }
  100%{
    stroke-dashoffset: -600;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: ${(props) => props.theme.sizes.zIndexUnder};
  width: 100%;
  height: 100%;
  pointer-events: none;

  ${(props) => props.theme.medias.maxXS} {
    display: none;
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  & {
    position: relative;
    justify-content: flex-end;
    height: 100%;
  }
`;

const Positioner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  width: 2px;
  height: 920px;
  background-color: ${(props) => props.theme.colors.border};
`;

const CircleOutline = styled.div`
  width: 200px;
  height: 200px;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 50%;
`;

const Group = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    align-items: center;
  }
`;

const CircleOrange = styled(Decor.Circle)`
  position: absolute;
  bottom: 920px;
  width: 140px;
  height: 140px;
`;

const CircleBlue = styled(Decor.Circle).attrs({ gradient: "secondary" })`
  position: absolute;
  bottom: 200px;
  width: 140px;
  height: 140px;
`;

const CircleOutlineForCircleBlue = styled(CircleOutline)`
  position: absolute;
  bottom: 120px;
  width: 300px;
  height: 300px;
`;

const GroupCircleIncomplete = styled(Group)`
  position: absolute;
  bottom: 1140px;
  right: -240px;
`;

const CircleOrangeIncomplete = styled(Decor.Circle).attrs({ isAnimated: true })`
  & {
    position: absolute;
    width: 360px;
    height: 360px;
    stroke-dasharray: 500;
  }
`;

const CircleOutlineForCircleOrangeIncomplete = styled(CircleOutline)`
  width: 326px;
  height: 326px;
`;

const RectangleOutline = styled.div`
  width: 200px;
  height: 200px;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 10px;
`;

const GroupRectangleIncomplete = styled(Group)`
  position: absolute;
  bottom: 540px;
  right: -360px;
`;

const RectangleOutlineForRectangleBlueIncomplete = styled(RectangleOutline)`
  width: 250px;
  height: 250px;
  border-radius: 40px;
`;

const RectangleBlueIncomplete = styled(Decor.Rectangle).attrs({
  gradient: "secondary",
})`
  width: 260px;
  height: 260px;
  animation-name: ${rectangleKeyframes};
  animation-duration: 10000ms;
  animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  animation-iteration-count: infinite;
  stroke-dasharray: 600;
  stroke-linecap: round;
  stroke-dashoffset: -100;
`;

const LineBlue = styled.div`
  position: absolute;
  left: -50px;
  bottom: 540px;
  z-index: 2;
  width: 8px;
  height: 120px;
  border-radius: 4px;
  background: ${(props) => props.theme.gradients.secondary};

  animation-name: ${lineKeyframes};
  animation-duration: 10000ms;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-iteration-count: infinite;
  animation-direction: alternate;
`;

const LineForLineBlue = styled.div`
  position: absolute;
  left: -47px;
  bottom: 490px;
  width: 2px;
  height: 220px;
  background-color: ${(props) => props.theme.colors.border};
`;

function Decoration() {
  return (
    <Wrapper>
      <Container>
        <Positioner>
          <Content>
            <Line />
            <CircleOrange />
            <CircleBlue />
            <CircleOutlineForCircleBlue />
            <GroupCircleIncomplete>
              <CircleOrangeIncomplete />
              <CircleOutlineForCircleOrangeIncomplete />
            </GroupCircleIncomplete>
            <GroupRectangleIncomplete>
              <RectangleBlueIncomplete />
              <RectangleOutlineForRectangleBlueIncomplete />
            </GroupRectangleIncomplete>
            <LineBlue />
            <LineForLineBlue />
          </Content>
        </Positioner>
      </Container>
    </Wrapper>
  );
}

export default Decoration;
