import styled from "styled-components";
import { tags } from "~/landing-labs/constants";
import Decoration from "./Decoration";
import Design from "./Design";
import Footer from "./Footer";
import Header from "./Header";
import Products from "./Products";
import Software from "./Software";
import Web3 from "./Web3";

const Wrapper = styled.div`
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.border};
`;

const Skills = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(${(props) => props.theme.sizes.edge} * 4);
`;

const List = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    row-gap: calc(${(props) => props.theme.sizes.edge} * 4);
    padding-bottom: calc(${(props) => props.theme.sizes.edge} * 4);
  }
`;

function Home() {
  return (
    <Wrapper data-availability={tags.pageRendered}>
      <Content>
        <Header />
        <Skills>
          <List>
            <Software />
            <Design />
            <Web3 />
          </List>
          <Decoration />
        </Skills>
        <Products />
        <Footer />
      </Content>
    </Wrapper>
  );
}

export default Home;
