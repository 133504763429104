import styled from "styled-components";
import {
  AtSymbolIcon,
  ChartPieIcon,
  CubeIcon,
} from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { Section } from "~/landing-labs/components/organisms";

const Wrapper = styled.div`
  width: 100%;
`;

function Design() {
  const { t } = useT();

  const skills = [
    {
      color: "secondaryMiddle" as const,
      description: t("landingLabs.productDesign.description"),
      icon: CubeIcon,
      title: t("landingLabs.productDesign.title"),
    },
    {
      color: "secondaryMiddle" as const,
      description: t("landingLabs.uiDesign.description"),
      icon: ChartPieIcon,
      title: t("landingLabs.uiDesign.title"),
    },
    {
      color: "secondaryMiddle" as const,
      description: t("landingLabs.visualDesign.description"),
      icon: AtSymbolIcon,
      title: t("landingLabs.visualDesign.title"),
    },
  ];

  return (
    <Wrapper id={"design"}>
      <Section
        description={t("landingLabs.design.description")}
        index={2}
        skills={skills}
        title={t("landingLabs.design.title")}
      />
    </Wrapper>
  );
}

export default Design;
