import styled from "styled-components";
import { Description, Title } from "~/landing-labs/components/atoms";
import { Skill } from "~/landing-labs/components/molecules";
import type { ComponentProps } from "react";

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 3);
  }
`;

const Top = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Skills = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  width: 100%;

  ${(props) => props.theme.medias.maxLG} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${(props) => props.theme.medias.maxMD} {
    grid-template-columns: repeat(2, 1fr);
    gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }

  ${(props) => props.theme.medias.maxXS} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface Props {
  description: ComponentProps<typeof Description>["value"];
  index: ComponentProps<typeof Title>["index"];
  isHighlighted?: ComponentProps<typeof Title>["isHighlighted"];
  title: ComponentProps<typeof Title>["value"];
  skills?: ComponentProps<typeof Skill>[];
}

function Section({
  description,
  index,
  isHighlighted = false,
  skills = [],
  title,
}: Props) {
  return (
    <Wrapper>
      <Container>
        <Top>
          <Title index={index} isHighlighted={isHighlighted} value={title} />
          <Description value={description} />
        </Top>
        <Skills>
          {skills.map((item) => (
            <Skill key={item.title} {...item} />
          ))}
        </Skills>
      </Container>
    </Wrapper>
  );
}

export default Section;
