import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { _ } from "@sablier/v2-mixins";
import Overlay from "./Overlay";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
    height: ${(props) => props.theme.sizes.toolbar};
    border-top: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    height: 100%;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 2);
  }
`;

const Divider = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Half = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    flex: 1;
    justify-content: flex-end;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 2);
    &:last-child {
      justify-content: flex-start;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxLG} {
    height: auto;
    min-height: ${(props) => props.theme.sizes.toolbar};

    ${Container} {
      ${(props) => props.theme.styles.column}
      & {
        row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
        align-items: center;
        padding: calc(${(props) => props.theme.sizes.edge} * 1) 0;
        text-align: center;
      }
    }

    ${Divider} {
      width: 200px;
      height: 2px;
    }
  }

  ${(props) => props.theme.medias.maxXS} {
    ${Half} {
      &:last-child {
        p {
          line-height: 1.8 !important;
        }
      }
    }
  }
`;

function Footer() {
  const { t } = useT();

  return (
    <Wrapper>
      <Overlay />
      <Container>
        <Half>
          <Button
            accent={"gray200"}
            appearance={"transparent"}
            purpose={"external"}
            title={_.capitalize(t("words.email"))}
            to={`mailto: ${links.email.contact}`}
          />
          <Button
            accent={"gray200"}
            appearance={"transparent"}
            purpose={"external"}
            title={_.capitalize(t("words.github"))}
            to={links.github.organization}
          />
          <Button
            accent={"gray200"}
            appearance={"transparent"}
            purpose={"external"}
            title={_.capitalize(t("words.twitter"))}
            to={links.twitter.product}
          />
        </Half>
        <Divider />
        <Half>
          <Button
            accent={"gray200"}
            appearance={"transparent"}
            isPreview
            title={t("about.address")}
          />
        </Half>
      </Container>
    </Wrapper>
  );
}

export default Footer;
