import styled from "styled-components";
import AssetLogoIcon from "@sablier/v2-assets/logo/icon-gradient.svg";
import { Icon } from "@sablier/v2-components/molecules";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-end;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Title = styled.div`
  & > h1 {
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    font-size: 68pt;
    line-height: 140%;
  }
`;

const Side = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: flex-start;
    align-items: center;
    width: 60px;
    height: 120px;
  }
`;

const Border = styled.svg.attrs((props) => props.theme.attributes.base)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Shape = styled.circle.attrs((props) => props.theme.attributes.base)`
  fill: none;
  stroke: ${(props) => props.theme.gradients.svgUrl.primary};
  stroke-width: 6px;
`;

function Circle() {
  return (
    <Border viewBox={"0 0 60 60"}>
      <Shape cx={"30"} cy={"30"} r={"27"} />
    </Border>
  );
}

const Stamp = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    --size: 28px;
    position: relative;
    flex-shrink: 0;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    align-items: center;

    ${Title} {
      h1 {
        font-size: 34pt;
      }
    }
    ${Side} {
      width: 40px;
      height: 40px;
      margin-top: 8px;
    }
    ${Shape} {
      stroke-width: 4px;
    }

    ${Stamp} {
      --size: 20px;
      width: 40px;
      height: 40px;
    }
  }
  ${(props) => props.theme.medias.maxXS} {
    ${(props) => props.theme.styles.column}
    & {
      gap: calc(${(props) => props.theme.sizes.edge} * 2);
      align-items: center;

      ${Title} {
        order: 2;
        width: 100%;
        h1 {
          font-size: 36pt;
        }
      }

      ${Side} {
        order: 1;
        width: auto;
        height: auto;
      }
      ${Stamp} {
        --size: 40px;
        width: 100px;
        height: 100px;
      }

      ${Shape} {
        stroke-width: 6px;
      }
    }
  }
`;

function Heading() {
  return (
    <Wrapper>
      <Side />
      <Title>
        <h1>Sablier Labs</h1>
      </Title>
      <Side>
        <Stamp>
          <Circle />
          <Icon purpose={"image"} value={AssetLogoIcon} />
        </Stamp>
      </Side>
    </Wrapper>
  );
}

export default Heading;
