import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-end;
    text-align: left;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Content = styled.div`
  max-width: 560px;
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

interface Props {
  className?: string;
  value?: string;
}

function Description({ children, className, value }: PropsWithChildren<Props>) {
  return (
    <Wrapper className={className}>
      <Content>
        {!_.isNilOrEmptyString(value) ? <p>{value}</p> : children}
      </Content>
    </Wrapper>
  );
}

export default Description;
