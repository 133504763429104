import styled from "styled-components";
import { Decor } from "@sablier/v2-components/atoms";

const Wrapper = styled.div`
  position: absolute;
  top: -60px;
  left: -60px;
  z-index: 30;
  pointer-events: none;

  ${(props) => props.theme.medias.maxXS} {
    display: none;
  }
`;

const CircleOrange = styled(Decor.Circle)`
  width: 120px;
  height: 120px;
`;

function Overlay() {
  return (
    <Wrapper>
      <CircleOrange />
    </Wrapper>
  );
}

export default Overlay;
