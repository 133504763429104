import styled from "styled-components";
import {
  BoltIcon,
  CodeBracketSquareIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { Section } from "~/landing-labs/components/organisms";

const Wrapper = styled.div`
  width: 100%;
`;

function Web() {
  const { t } = useT();
  const skills = [
    {
      color: "dark100" as const,
      description: t("landingLabs.contracts.description"),
      icon: BoltIcon,
      title: t("landingLabs.contracts.title"),
    },
    {
      color: "dark100" as const,
      description: t("landingLabs.data.description"),
      icon: MagnifyingGlassIcon,
      title: t("landingLabs.data.title"),
    },
    {
      color: "dark100" as const,
      description: t("landingLabs.error.description"),
      icon: CodeBracketSquareIcon,
      title: t("landingLabs.error.title"),
    },
  ];
  return (
    <Wrapper>
      <Section
        description={t("landingLabs.web.description")}
        index={3}
        isHighlighted
        skills={skills}
        title={t("landingLabs.web.title")}
      />
    </Wrapper>
  );
}

export default Web;
