import styled from "styled-components";
import { ArrowRightIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from "@sablier/v2-assets/logo/long-gradient.svg";
import { Internal } from "@sablier/v2-components/atoms";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import {
  Description as DescriptionPartial,
  Title as TitlePartial,
} from "~/landing-labs/components/atoms";
import Underlay from "./Underlay";

const Wrapper = styled.div`
  position: relative;
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
  padding-top: calc(${(props) => props.theme.sizes.edge} * 6);
  padding-bottom: calc(${(props) => props.theme.sizes.edge} * 10);
  border-top: 2px solid ${(props) => props.theme.colors.border};
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 4);
    justify-content: center;
    align-items: center;
  }
`;

const Element = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    row-gap: calc(${(props) => props.theme.sizes.edge} * 4);
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled(TitlePartial)`
  p {
    text-align: center;
  }
`;
const Description = styled(DescriptionPartial)`
  p {
    max-width: 460px;
    text-align: center;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  max-width: 160px;
  background-color: ${(props) => props.theme.colors.border};
`;

const Branding = styled(Internal)`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 180px;
    height: 60px;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
  & > * {
    min-height: 64px;
    padding-inline: 24px;
  }

  ${(props) => props.theme.medias.maxMD} {
    grid-template-columns: 1fr;
  }
`;

function Products() {
  const { t } = useT();

  return (
    <Wrapper>
      <Underlay />
      <Content id={"products"}>
        <Container>
          <Title value={t("landingLabs.products.title")} />
          <Divider />
          <Element>
            <Branding to={links.v1.landing}>
              <Logo />
            </Branding>
            <Description value={t("landingLabs.products.description")} />
            <Actions>
              <Button
                accent={"dark300"}
                appearance={"solid"}
                purpose={"external"}
                right={GlobeAltIcon}
                title={t("structs.visitWebsite")}
                to={links.v2.landing}
              />
              <Button
                accent={"dark300"}
                appearance={"solid"}
                purpose={"external"}
                right={ArrowRightIcon}
                title={t("structs.useApp")}
                to={links.v2.client}
              />
            </Actions>
          </Element>
        </Container>
      </Content>
    </Wrapper>
  );
}

export default Products;
