import styled from "styled-components";
import { Decor } from "@sablier/v2-components/atoms";

const Wrapper = styled.div`
  position: absolute;
  z-index: ${(props) => props.theme.sizes.zIndexOver};
  width: 100%;
  height: 100%;
`;

const Background = styled(Decor.Background)`
  & > * {
    margin-top: -200px;
  }
`;

function Underlay() {
  return (
    <Wrapper>
      <Background left={"center"} right={"center"} />
    </Wrapper>
  );
}

export default Underlay;
