import styled from "styled-components";
import {
  ChevronDoubleDownIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import Heading from "./Heading";
import Toolbar from "./Toolbar";
import Underlay from "./Underlay";

const WrapperPartial = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    position: relative;
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 100%;
    border-bottom: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.column}
  & {
    justify-content: center;
    align-items: center;
    height: 600px;
    border-right: 2px solid ${(props) => props.theme.colors.border};
    border-left: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Subtitle = styled.div`
  margin-top: calc(${(props) => props.theme.sizes.edge} * 3);
  margin-bottom: calc(${(props) => props.theme.sizes.edge} * 4);
  & > h2 {
    color: ${(props) => props.theme.colors.gray100};
    font-weight: 500;
    font-size: 22pt;
    line-height: 24pt;
  }
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(${(props) => props.theme.sizes.edge} * 1);
  margin: 0 auto;
`;

const Wrapper = styled(WrapperPartial)`
  ${(props) => props.theme.medias.maxMD} {
    ${Container} {
      height: auto;
      min-height: 600px;
      padding-top: calc(${(props) => props.theme.sizes.edge} * 2);
      padding-bottom: calc(${(props) => props.theme.sizes.edge} * 4);
    }

    ${Subtitle} > * {
      font-size: 18pt;
    }
  }
  ${(props) => props.theme.medias.maxSM} {
    ${Container} {
      min-height: 500px;
      text-align: center !important;
    }
    ${Subtitle} {
      max-width: 300px;
      margin-top: calc(${(props) => props.theme.sizes.edge} * 2);
    }
    ${Actions} {
      grid-template-columns: 1fr;
    }
  }
`;

function Header() {
  const { t } = useT();
  return (
    <Wrapper>
      <Underlay />
      <Content>
        <Toolbar />
        <Container>
          <Heading />
          <Subtitle>
            <h2>{t("landingLabs.subtitle")}</h2>
          </Subtitle>
          <Actions>
            <Button
              accent={"iconicPrimary"}
              appearance={"outline"}
              purpose={"internal"}
              right={ChevronDoubleDownIcon}
              title={t("structs.readMore")}
              to={"#design"}
            />
            <Button
              accent={"iconic"}
              appearance={"outline"}
              purpose={"external"}
              right={PaperAirplaneIcon}
              title={t("structs.contactUs")}
              to={`mailto: ${links.email.contact}`}
            />
          </Actions>
        </Container>
      </Content>
    </Wrapper>
  );
}

export default Header;
