import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import Link from "next/link";
import type { Props as SideProps } from "./Side";
import type { Props as TitleProps } from "./Title";
import type { AccentKey, AppearanceKey, PurposeKey } from "./designer";
import type { MouseEvent } from "react";
import type { FlattenSimpleInterpolation } from "styled-components";
import Side from "./Side";
import Title from "./Title";
import { configuration, useDesigner, useStyler } from "./designer";

/**
 * ⚙️ Notes on configuration
 * --------------------------------------------
 * Design (Accents and Appearance)
 * -- See ./designer.ts
 * ---------------
 * Loading
 * - it will prioritize replacing the already existing icon a spinner: X [] => X ()
 * - if there's a forced side fed to it, it will prioritize that side: [] X + right  => [] X ()
 * - if there is no prior icon, it will show the spinner on the right side: X => X ()
 * - if there are two existing icons, it will show the spinner on the right side: [] X [] => [] X ()
 * ---------------
 * Title
 * - it will adapt the title based on the size of the screen [short, medium, large (default)]
 */

const Wrapper = styled.div<{ inline: FlattenSimpleInterpolation }>`
  ${(props) => props.theme.styles.row}
  ${(props) => props.inline}

  & {
    justify-content: center;
    align-items: center;
    height: ${(props) => props.theme.sizes.button};
    min-width: ${(props) => props.theme.sizes.button};
    padding-inline: 12px;
    column-gap: 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  &[data-disabled="true"],
  &[data-disabled="soft"] {
    cursor: not-allowed;
    user-select: none;
  }

  &[data-disabled="true"] {
    color: ${(props) => props.theme.colors.dark700};
    border: 2px solid ${(props) => props.theme.colors.dark000} !important;
    background: ${(props) => props.theme.colors.dark000} !important;
  }

  &[data-preview="true"] {
    cursor: default;
  }
  &[data-mini="true"] {
    height: ${(props) => props.theme.sizes.buttonMini};
    min-width: ${(props) => props.theme.sizes.buttonMini};
    padding-inline: 6px;
    column-gap: 2px;
  }

  *,
  p,
  & > p {
    color: currentColor;
  }
`;

export interface Props extends TitleProps {
  accent?: AccentKey;
  appearance?: AppearanceKey;
  className?: string;
  forceLoadingSide?: "left" | "right";
  /** Prevents both physical and visual interactions */
  isDisabled?: boolean | "soft";
  isLoading?: boolean;
  isMini?: boolean;
  /** Prevents visual interactions but keeps the physical ones */
  isPreview?: boolean;
  /** In case of internal purpose, should the router push or replace */
  isReplacing?: boolean;
  left?: SideProps["value"];
  leftPurpose?: SideProps["purpose"];
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  purpose?: PurposeKey;
  right?: SideProps["value"];
  rightPurpose?: SideProps["purpose"];
  target?: string;
  to?: string;
  tooltip?: string;
  cy?: string;
}

function Button({
  accent = configuration.accent.dark as AccentKey,
  appearance = configuration.appearance.solid as AppearanceKey,
  className,
  forceLoadingSide,
  isDisabled = false,
  isLoading = false,
  isMini = false,
  isPreview = false,
  isReplacing = false,
  left,
  leftPurpose = "heroicon",
  onClick: callback,
  purpose = configuration.purpose.button as PurposeKey,
  right,
  rightPurpose = "heroicon",
  target = "_blank",
  to,
  tooltip,
  title,
  titleMedium,
  titleShort,
  cy,
}: Props) {
  const design = useDesigner(accent, appearance);
  const style = useStyler(design);

  const titleProps = useMemo(
    () => ({
      isMini,
      title,
      titleMedium,
      titleShort,
    }),
    [isMini, title, titleMedium, titleShort],
  );

  const loadingSide = useMemo(() => {
    if (forceLoadingSide === "right" || forceLoadingSide === "left") {
      return forceLoadingSide;
    } else {
      if (!_.isNil(left) && !_.isNil(right)) {
        return "right";
      }
      if (!_.isNil(left) && _.isNil(right)) {
        return "left";
      }
      return "right";
    }
  }, [forceLoadingSide, left, right]);

  const renderRightSide = useCallback(() => {
    if (isLoading) {
      if (loadingSide === "right") {
        return <Side purpose={"spinner"} />;
      }
    }
    if (!_.isNilOrEmptyString(rightPurpose) && !_.isNil(right)) {
      return <Side isMini={isMini} purpose={rightPurpose} value={right} />;
    }
    return false;
  }, [loadingSide, isLoading, isMini, right, rightPurpose]);

  const renderLeftSide = useCallback(() => {
    if (isLoading) {
      if (loadingSide === "left") {
        return <Side purpose={"spinner"} />;
      }
    }
    if (!_.isNilOrEmptyString(leftPurpose) && !_.isNil(left)) {
      return <Side isMini={isMini} purpose={leftPurpose} value={left} />;
    }
    return false;
  }, [loadingSide, isLoading, isMini, left, leftPurpose]);

  const renderBody = useCallback(() => {
    return (
      <>
        {renderLeftSide()}
        <Title {...titleProps} />
        {renderRightSide()}
      </>
    );
  }, [renderLeftSide, renderRightSide, titleProps]);

  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isDisabled) {
        event.preventDefault();
        return;
      } else if (callback) {
        callback(event);
      }
    },
    [callback, isDisabled],
  );

  if (purpose === configuration.purpose.external) {
    return (
      <Wrapper
        as={"a"}
        className={className}
        href={to}
        data-component={"button"}
        data-cy={cy}
        data-disabled={isDisabled}
        data-preview={isPreview}
        data-loading={isLoading}
        data-mini={isMini}
        inline={style}
        onClick={onClick}
        rel={"noopener noreferrer"}
        target={target}
        title={tooltip}
      >
        {renderBody()}
      </Wrapper>
    );
  }

  if (
    purpose === configuration.purpose.internal &&
    !_.isNil(to) &&
    !isDisabled
  ) {
    return (
      <Link passHref href={to} replace={isReplacing} shallow legacyBehavior>
        <Wrapper
          as={"a"}
          className={className}
          data-component={"button"}
          data-cy={cy}
          data-disabled={isDisabled}
          data-loading={isLoading}
          data-preview={isPreview}
          data-mini={isMini}
          inline={style}
          onClick={onClick}
          title={tooltip}
        >
          {renderBody()}
        </Wrapper>
      </Link>
    );
  }

  return (
    <Wrapper
      className={className}
      data-component={"button"}
      data-cy={cy}
      data-disabled={isDisabled}
      data-loading={isLoading}
      data-preview={isPreview}
      data-mini={isMini}
      inline={style}
      onClick={onClick}
      title={tooltip}
    >
      {renderBody()}
    </Wrapper>
  );
}

export default Button;
