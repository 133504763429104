import styled from "styled-components";
import {
  ComputerDesktopIcon,
  DocumentMagnifyingGlassIcon,
  ServerStackIcon,
} from "@heroicons/react/24/outline";
import { useT } from "@sablier/v2-locales";
import { Section } from "~/landing-labs/components/organisms";

const Wrapper = styled.div`
  width: 100%;
`;

function Software() {
  const { t } = useT();

  const skills = [
    {
      description: t("landingLabs.frontend.description"),
      icon: ComputerDesktopIcon,
      title: t("landingLabs.frontend.title"),
    },
    {
      description: t("landingLabs.backend.description"),
      icon: ServerStackIcon,
      title: t("landingLabs.backend.title"),
    },
    {
      description: t("landingLabs.smartContracts.description"),
      icon: DocumentMagnifyingGlassIcon,
      title: t("landingLabs.smartContracts.title"),
    },
  ];
  return (
    <Wrapper>
      <Section
        description={t("landingLabs.software.description")}
        index={1}
        skills={skills}
        title={t("landingLabs.software.title")}
      />
    </Wrapper>
  );
}

export default Software;
