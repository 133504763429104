import { memo, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { _ } from "@sablier/v2-mixins";

const WrapperPartial = styled.div`
  z-index: 2;
  margin-right: 4px;
  margin-left: 4px;
  &:empty {
    display: none;
  }
`;

const Large = styled.p`
  ${(props) => props.theme.styles.textButton};

  & {
    display: inline;
    color: inherit;
    text-align: center;
  }

  ${(props) => props.theme.medias.maxMD} {
    display: none;
  }
`;

const Medium = styled(Large)`
  display: none;

  ${(props) => props.theme.medias.maxMD} {
    display: inline;
  }

  ${(props) => props.theme.medias.maxSM} {
    display: none;
  }
`;

const Short = styled(Large)`
  display: none;

  ${(props) => props.theme.medias.maxSM} {
    display: inline !important;
  }
`;

interface HiddenProps {
  isNormalHidden: boolean;
  isMediumHidden: boolean;
  isShortHidden: boolean;
}

const Wrapper = styled(WrapperPartial)<HiddenProps>`
  &[data-mini="true"] {
    ${Large} {
      ${(props) => props.theme.styles.textButtonMini};
    }
  }

  ${(props) => props.theme.medias.maxLG} {
    ${(props) =>
      props.isNormalHidden &&
      css`
        display: none;
      `}
  }

  ${(props) => props.theme.medias.maxMD} {
    ${(props) =>
      props.isMediumHidden &&
      css`
        display: none;
      `}
  }

  ${(props) => props.theme.medias.maxSM} {
    ${(props) =>
      props.isShortHidden &&
      css`
        display: none;
      `}
  }
`;

export interface Props {
  isMini?: boolean;
  title?: string | JSX.Element | JSX.Element[];
  titleShort?: string | JSX.Element | JSX.Element[]; // empty string will hide the title on small screens
  titleMedium?: string | JSX.Element | JSX.Element[]; // empty string will hide the title on medium screens
}

function Title({
  isMini = false,
  title,
  titleMedium = undefined,
  titleShort = undefined,
}: Props) {
  const renderTitleSizes = useCallback(() => {
    const large = !_.isNil(title) ? <Large>{title}</Large> : false;

    const valueM = !_.isNil(titleMedium) ? titleMedium : title;
    const medium = !_.isNilOrEmptyString(valueM) ? (
      <Medium>{valueM}</Medium>
    ) : (
      false
    );

    const valueS = !_.isNil(titleShort) ? titleShort : valueM;
    const short = !_.isNilOrEmptyString(valueS) ? (
      <Short>{valueS}</Short>
    ) : (
      false
    );

    return (
      <>
        {large}
        {medium}
        {short}
      </>
    );
  }, [title, titleMedium, titleShort]);

  const isEmpty = useMemo(() => {
    return [title, titleMedium, titleShort].every((t) =>
      _.isNilOrEmptyString(t),
    );
  }, [title, titleMedium, titleShort]);

  const isNormalHidden = useMemo(() => title === "", [title]);
  const isMediumHidden = useMemo(() => titleMedium === "", [titleMedium]);
  const isShortHidden = useMemo(() => titleShort === "", [titleShort]);

  if (isEmpty) {
    return false;
  }

  return (
    <Wrapper
      data-component={"title"}
      data-mini={isMini}
      isNormalHidden={isNormalHidden}
      isMediumHidden={isMediumHidden}
      isShortHidden={isShortHidden}
    >
      {renderTitleSizes()}
    </Wrapper>
  );
}

export default memo(Title);
