import styled from "styled-components";
import { Icon } from "@sablier/v2-components/molecules";
import { rgba } from "polished";
import type { ThemeType } from "@sablier/v2-themes";
import type { ComponentProps } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    grid-column: span 1;
    row-gap: calc(${(props) => props.theme.sizes.edge} * 1);
    width: 100%;
    height: 100%;
    padding: calc(${(props) => props.theme.sizes.edge} * 3 / 2);
    border-radius: 12px;
    background-color: ${(props) => props.theme.colors.dark200};
  }
`;

const Box = styled.div<{ color: keyof ThemeType["colors"] }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;
  margin-bottom: calc(${(props) => props.theme.sizes.edge} * 1);
  border-radius: 12px;
  background-color: ${(props) => rgba(props.theme.colors.white, 0.05)};
  overflow: hidden;
  &:before {
    position: absolute;
    content: "";
    z-index: ${(props) => props.theme.sizes.zIndexUnder};
    width: 60px;
    height: 60px;
    margin-top: -70px;
    margin-right: -70px;
    background: ${(props) => props.theme.colors[props.color]};
    filter: blur(20px);
    transform: translate(0, 0) scale(1);
    transition: transform 200ms;
  }

  svg {
    stroke-width: 1.8 !important;
    opacity: 0.9;
  }
  & > * {
    z-index: ${(props) => props.theme.sizes.zIndexOver};
  }
`;

const Title = styled.div`
  & > p {
    ${(props) => props.theme.styles.textBase}
    & {
      color: ${(props) => props.theme.colors.gray100};
      font-size: 16pt;
      line-height: 20pt;
    }
  }
`;

const Description = styled.div`
  & > p {
    ${(props) => props.theme.styles.textParagraph}
    & {
      color: ${(props) => props.theme.colors.gray200};
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.dark300};
    transition: background-color 200ms;

    ${Box} {
      &:before {
        transform: translate(-10px, 10px) scale(1);
        transition: transform 200ms;
      }
    }
  }
`;

export interface Props {
  className?: string;
  color?: keyof ThemeType["colors"];
  description: string;
  icon: ComponentProps<typeof Icon>["value"];
  iconPurpose?: ComponentProps<typeof Icon>["purpose"];
  title: string;
}

function Skill({
  className,
  color = "orange",
  description,
  icon,
  iconPurpose,
  title,
}: Props) {
  return (
    <Wrapper className={className}>
      <Box color={color}>
        <Icon purpose={iconPurpose} size={32} value={icon} />
      </Box>
      <Title>
        <p>{title}</p>
      </Title>
      <Description>
        <p>{description}</p>
      </Description>
    </Wrapper>
  );
}

export default Skill;
