import styled from "styled-components";
import { Button } from "@sablier/v2-components/molecules";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";

const Wrapper = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    width: 100%;
    height: ${(props) => props.theme.sizes.toolbar};
    border-bottom: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Container = styled.div`
  ${(props) => props.theme.styles.container}
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    height: 100%;
    border-right: 2px solid ${(props) => props.theme.colors.border};
    border-left: 2px solid ${(props) => props.theme.colors.border};
  }
`;

const Menu = styled.menu`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    margin: 0;
    padding: 0;
    column-gap: calc(${(props) => props.theme.sizes.edge}) * 3;
  }
`;

function Toolbar() {
  const { t } = useT();
  return (
    <Wrapper>
      <Container>
        <Menu>
          <Button
            accent={"gray100"}
            appearance={"transparent"}
            title={t("words.services")}
            purpose={"internal"}
            to={"#design"}
          />
          <Button
            accent={"gray100"}
            appearance={"transparent"}
            title={t("words.products")}
            purpose={"internal"}
            to={"#products"}
          />
          <Button
            accent={"gray100"}
            appearance={"transparent"}
            title={t("words.contact")}
            purpose={"external"}
            to={`mailto: ${links.email.contact}`}
          />
        </Menu>
      </Container>
    </Wrapper>
  );
}

export default Toolbar;
