import styled from "styled-components";
import { _ } from "@sablier/v2-mixins";
import type { PropsWithChildren } from "react";

const WrapperPartial = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    align-items: flex-end;
    text-align: left;
    column-gap: calc(${(props) => props.theme.sizes.edge} * 1 / 2);
  }
`;

const Content = styled.div`
  & > h3 {
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
    font-size: 24pt;
  }
`;

const Side = styled.div`
  ${(props) => props.theme.styles.column}
  & {
    justify-content: flex-start;
    align-items: center;
    height: 60px;
  }
`;

const Count = styled.div`
  ${(props) => props.theme.styles.row}
  & {
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.dark400};

    & > p {
      color: ${(props) => props.theme.colors.white};
      font-weight: 800;
      font-size: 16pt;
      text-align: center;
    }
  }
`;

const Wrapper = styled(WrapperPartial)`
  &[data-highlight="true"] {
    ${Count} {
      background-color: ${(props) => props.theme.colors.white};
      & > p {
        color: ${(props) => props.theme.colors.dark100};
      }
    }
  }
`;

interface Props {
  className?: string;
  index?: number;
  isHighlighted?: boolean;
  value?: string;
}

function Title({
  children,
  className,
  index,
  isHighlighted = false,
  value,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper className={className} data-highlight={isHighlighted}>
      <Content>
        {!_.isNilOrEmptyString(value) ? <h3>{value}</h3> : children}
      </Content>
      <Side>
        {!_.isNilOrEmptyString(index) && (
          <Count>
            <p>{index}</p>
          </Count>
        )}
      </Side>
    </Wrapper>
  );
}

export default Title;
